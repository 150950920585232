import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CognitoService} from "../../../services/auth/cognito.service";
import {UtilsService} from "../../../services/utils.service";
import {SupplierModel} from "../../shared/models/supplier.model";
import {SupplierDataService} from "../services/supplier-data.service";
import { ManagerService } from '../../../services/shared/manager.service';
import { da } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class SupplierGuard implements CanActivate, CanActivateChild {

  constructor(
    private cognito: CognitoService,
    private _util: UtilsService,
    private _supplierData: SupplierDataService,
    private router: Router,
    private _manager: ManagerService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkSupplier();
      check? resolve(true): reject("Check false")
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkSupplier();
      check? resolve(true): reject("Check false")
    });  }

  async checkSupplier(){
    const accessToken = await this.cognito.getCredsUser('access')
    if(accessToken){
      this._util.toogleFullSpinner(true)

      return await this.cognito.signInApi()
        .then((data: any) => {
          this._util.toogleFullSpinner(false)
          const supplier: SupplierModel | null = data.user
          if(!['supplier', 'manager_supplier'].includes(data.group)){
            this.router.navigate(['/landing/signin'])
            return false;
          }
          if(!data || supplier?.stepSignUp && supplier?.stepSignUp < 7){
            this.router.navigate(['/landing/steps'])
            return false;
          }
          if (data.group === 'manager_supplier') {
            this._supplierData.setSupplier(data.user.supplier)
            this._manager.setManager(data.user, data.isManager)
          }

          if(data.group === 'supplier'){
            this._supplierData.setSupplier(data.user)
            this._manager.setManager(data.user.managers)
          }
          return true
        })
        .catch(err =>{
          this._util.toogleFullSpinner(false)
          this.router.navigate(['/landing'])
          this.cognito.logOut()
          return false;
        })
    }
    this.router.navigate(['/landing'])
    return false;
  }

}
