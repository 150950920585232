import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, firstValueFrom, forkJoin} from "rxjs";
import {TranslateLoader} from "@ngx-translate/core";
import {ResourcesService} from "./resources.service";

@Injectable()
export class TranslateLoaderService implements TranslateLoader{
  translations: BehaviorSubject<any> = new BehaviorSubject<null>(null)
  defaultTranslations: any;
  lang = "es";

  constructor(
    private httpClient: HttpClient,
    private _resources: ResourcesService
  ) {
  }

  getTranslation(lang: string){
    //Only support for es.json in case of change, it's needed to configure multiple language support
    this.lang = lang;
    this.initTranslations()
    return this.translations;
  }

  async initTranslations(){
    try{

      const resTrans:[any, any] = await firstValueFrom(forkJoin(this.httpClient.get("assets/i18n/es.json"),this._resources.getGeneralTranslations()))

      this.defaultTranslations = resTrans[0]
      const trans = resTrans[1]? JSON.parse(resTrans[1].description): {}
      this.translations.next({...this.defaultTranslations, ...trans})

      this._resources.translationsReady.next(true);
    }catch (e: any){
      console.log(e)
    }
  }
}
