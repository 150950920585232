import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, firstValueFrom, map} from "rxjs";
import {FilterModel, FilterTypes, getEnumType} from "../../modules/shared/models/filter.model";
import {CognitoService} from "../auth/cognito.service";

export interface Filter{
  type: 'TECNOLOGY' |
  'INDUSTRY' |
  'INFRASTRCUTURE' |
  'DATABASE' |
  'PROYECT_TYPE' |
  'CMS' |
  'DEVOLPMENT_TOOLS' |
  'LANGUAGE'
}

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  url = `${environment.apiURL}/resources`

  translationsReady = new BehaviorSubject(false);
  filters: FilterModel[] = [];


  constructor(private http:HttpClient, private cognito:CognitoService) { }

  setFilters(filters:FilterModel[]){
    this.filters = filters;
  }

  getFilterByType(type:FilterTypes){
    return this.filters.filter(filter=>{
      return getEnumType(String(filter.type)) === type
    })
  }

  async addFilter(filter: { name?: string, type?: FilterTypes, from: string }, from : "supplier" | "client" = "supplier"){
    const token = await this.cognito.getCredsUser('access')

    return this.http.post(`${this.url}/${from}/filters`, filter,{
      headers:{
        authorization: `Bearer ${token}`
      }
    })
  }

  getFilters(){
    return this.http.get( `${this.url}/filters`).pipe(
      map((data:any): FilterModel[] => {
        return data;
      })
    )
  }

  async getMedia(id:string){
    const token = await this.cognito.getCredsUser('access');
    return this.http.get(`${this.url}/file?id=${id}`,{
      headers:{
        authorization: `Bearer ${token}`
      }
    })
  }

  async uploadMediaBySignedUrl(url:string, file: File){
    return await firstValueFrom(this.http.put(url, file))
  }

  getGeneralTranslations(){
    return this.http.get(`${this.url}/translations`)
  }

  async sendContact(body: any){
    const token = await this.cognito.getCredsUser('access');
    return this.http.post(`${this.url}/contact`, body,{
      headers:{
        authorization: `Bearer ${token}`
      }
    })
  }

}
