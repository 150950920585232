import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  private _snackError = new Subject<string>();
  private _snackSuccess = new Subject<string>();
  constructor() { }

  snackErrorChanged() {
    return this._snackError.asObservable();
  }

  sendSnackError(message: string) {
    this._snackError.next(message);
  }

  snackSuccessChanged() {
    return this._snackSuccess.asObservable();
  }

  sendSnackSuccess(message: string) {
    this._snackSuccess.next(message);
  }
}
