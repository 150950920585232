import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SupplierModel} from "../../shared/models/supplier.model";
import {CookieService} from "ngx-cookie-service";
import {addDays} from "date-fns";
import {ClientModel} from "../../shared/models/client.model";

export interface MatchGenerate{
  id?: string,
  suppliers: {match?: number, supplier: SupplierModel }[],
  values: {
    cost?: number,
    experience?: string,
    filters: { [key:string]: {value: string[], type?: number} }
  }
}

@Injectable({
  providedIn: 'any'
})
export class ClientDataService {
  currentMatch: BehaviorSubject<MatchGenerate| null> = new BehaviorSubject<MatchGenerate | null>(null)
  client: BehaviorSubject<ClientModel | null> = new BehaviorSubject<ClientModel | null>(null)

  constructor(
    private http: HttpClient,
    private _cookies: CookieService
  ) { }

  getWelcomeModal(){
    let modal = this._cookies.get('welcomeModalClient')
    if(modal){
      return JSON.parse(modal)
    }
    return false
  }
  setWelcomeModal(state: boolean){
    console.log(addDays(new Date(), 365))
    this._cookies.set('welcomeModalClient', JSON.stringify(state), addDays(new Date(), 365), '/')
  }

  setClient(client: any){
    this.client.next(client)
  }

  getClient(){
    return this.client.getValue()
  }

  setMatch(match: any){
    this.currentMatch.next(match)
  }

  getMatch(){
    return this.currentMatch.getValue()
  }

}
