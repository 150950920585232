import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackService} from "./services/snack/snack.service";
import {UtilsService} from "./services/utils.service";
import {CookieService} from "ngx-cookie-service";
import {ResourcesService} from "./services/resources/resources.service";
import {firstValueFrom, forkJoin} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Proveedor';
  loading = false;


  constructor(
    private _snack:MatSnackBar,
    private snackService:SnackService,
    private utilService:UtilsService,
    private _spinner: NgxSpinnerService,
    private _resources: ResourcesService,
    private cookiesService: CookieService,
    private translate: TranslateService
  ) {
    this.snackService.snackErrorChanged().subscribe(message => {
      this.openSnackError(message);
    })
    this.cookiesService.delete('steps')
    this.snackService.snackSuccessChanged().subscribe(message => {
      this.openSnackSuccess(message);
    })
    this.utilService.fullSpinnerChanged().subscribe(show => {
      if (show){
        this._spinner.show('main')
      }else{
        this._spinner.hide('main')
      }
    })
  }

  ngOnInit() {
    this.initApp();
  }

  async initApp(){
    this.utilService.toogleFullSpinner(true);
    this.loading = true;
    try{
      const res = await firstValueFrom(
        forkJoin([
          this.fetchFilters(),
          new Promise((resolve, reject) =>{
            this.translate.setDefaultLang('es');
            const subscription = this._resources.translationsReady.subscribe(data=>{
              if(data){
                subscription.unsubscribe()
                this.translate.reloadLang("es")
                resolve(true)
              }
            })
          })
        ])
      )
    }catch (e) {
      console.log(e)
    }finally {
      this.loading = false;
      this.utilService.toogleFullSpinner(false);
    }
  }

  async fetchFilters(){
    try {
      const data = await firstValueFrom(this._resources.getFilters());
      this._resources.setFilters(data.sort((a,b)=> a.label < b.label? -1:1))
    }catch (e: any) {
      console.log(e)
    }
  }

  openSnackError(message: string) {
    setTimeout(() => this._snack.open(message, 'x', {
      panelClass: ['snack-error'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
    }));
  }

  openSnackSuccess(message: string) {
    setTimeout(() => this._snack.open(message, 'x', {
      panelClass: ['snack-success'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
    }));
  }
}
