import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {authErrors} from "./auth/cognito.service";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private _fullSpinner = new BehaviorSubject<boolean>(false);
  constructor() { }

  fullSpinnerChanged() {
    return this._fullSpinner.asObservable();
  }

  toogleFullSpinner(show: boolean) {
    return this._fullSpinner.next(show);
  }

  findError(message: string){
    for(let error of Object.keys(authErrors)){
      if(message.includes(error)){
        return authErrors[error]
      }
    }
    return message;
  }
}
