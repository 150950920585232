import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import '@angular/common/locales/global/es-CL';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxSpinnerModule} from "ngx-spinner";
import {CookieService} from "ngx-cookie-service";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { ConfirmModalComponent } from './modules/shared/components/base/confirm-modal/confirm-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {TranslateLoaderService} from "./services/resources/translate-loader.service";
import {ResourcesService} from "./services/resources/resources.service";
import 'codemirror/mode/gherkin/gherkin';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/indent-fold';
@NgModule({
    declarations: [
        AppComponent,
        ConfirmModalComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgxSpinnerModule.forRoot({type: 'square-loader'}),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [HttpClient, ResourcesService]
      }
    }),
    MatDialogModule,
    MatButtonModule
  ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
