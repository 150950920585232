export interface FilterModel {
  id: string
  name: string
  label: string
  type: FilterTypes
}

export interface FilterOnlyIdModel{
  ids: string[]
  types: string[]
}

export const getEnumType = (key: string)=>{
  return FilterTypes[key as keyof typeof FilterTypes]
}

export enum FilterTypes {
  DEVELOPMENT_TECHNOLOGY = 0,
  INDUSTRY = 1,
  INFRASTRUCTURE = 2,
  DATABASE = 3,
  PROJECT_TYPE = 4,
  CMS = 5,
  DEVELOPMENT_TOOLS = 6,
  LANGUAGE = 7,
  SECTOR = 8,
  CERTIFICATES = 9,
  SIZE = 10,
  EXPERIENCE = 11,
  COMPLETED_PROJECTS = 12,
  SIZE_CLIENT
}
