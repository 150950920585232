import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SupplierGuard} from "./modules/supplier/guards/supplier.guard";
import {ClientGuard} from "./modules/client/guards/client.guard";
import {AdministratorGuard} from "./modules/administrator/guards/administrator.guard";

const routes: Routes = [
  {
    path: "landing",
    loadChildren: ()=> import('./modules/unauthenticated/unauthenticated.module').then(m => m.UnauthenticatedModule),
  },
  {
    path: "supplier",
    canActivate: [SupplierGuard],
    loadChildren: ()=> import('./modules/supplier/supplier.module').then(m => m.SupplierModule),
  },
  {
    path: "client",
    canActivate: [ClientGuard],
    loadChildren: ()=> import('./modules/client/client.module').then(m => m.ClientModule),
  },
  {
    path: "panel",
    canActivate: [AdministratorGuard],
    loadChildren: ()=> import('./modules/administrator/administrator.module').then(m => m.AdministratorModule),
  },
  {path:"**", pathMatch:"full", redirectTo:"/landing"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
