import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {addDays} from "date-fns";
import {SupplierModel} from "../../shared/models/supplier.model";
import {BehaviorSubject} from "rxjs";
import {NotificationModel} from "../../shared/models/notification.model";
import {environment} from "../../../../environments/environment";
import {CognitoService} from "../../../services/auth/cognito.service";

@Injectable({
  providedIn: 'root'
})
export class SupplierDataService {
  baseUri = `${environment.apiURL}/supplier`
  supplier: BehaviorSubject<SupplierModel | null> = new BehaviorSubject<SupplierModel | null>(null);
  notifications:BehaviorSubject<NotificationModel[] | null> = new BehaviorSubject<NotificationModel[] | null>(null)

  constructor(
    private http: HttpClient,
    private _cookies: CookieService,
    private cognito: CognitoService
  ) { }

  getWelcomeModal(){
    let modal = this._cookies.get('welcomeModalSupplier')
    if(modal){
      return JSON.parse(modal)
    }
    return false
  }

  setSupplier(supplier: SupplierModel){
    this.supplier.next(supplier)
  }

  getSupplier(){
    return this.supplier.getValue()
  }

  getSupplierAsObservable(){
    return this.supplier.asObservable()
  }

  setWelcomeModal(state: boolean){
    this._cookies.set('welcomeModalSupplier', JSON.stringify(state),  addDays(new Date(), 365), '/'
    )
  }

  setNotifications(notifications: NotificationModel[]){
    this.notifications.next(notifications)
  }

  async getNotifications(){
    const token = await this.cognito.getCredsUser('access');

    return this.http.get(`${this.baseUri}/notifications`,{
      headers:{
        authorization: `Bearer ${token}`
      }
    })
  }

  getNotificationsObs(){
    return this.notifications.asObservable()
  }

  checkLogo(url: string){
    return this.http.get(url)
  }

  async viewNotifications(id:string){
    const token = await this.cognito.getCredsUser('access');

    return this.http.put(`${this.baseUri}/notifications/${id}`,{},{
      headers:{
        authorization: `Bearer ${token}`
      }
    })
  }

  async viewAllNotifications(){
    const token = await this.cognito.getCredsUser('access');

    return this.http.put(`${this.baseUri}/notifications`,{},{
      headers:{
        authorization: `Bearer ${token}`
      }
  })
}
}
