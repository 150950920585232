<router-outlet *ngIf="!loading"></router-outlet>
<ngx-spinner
  bdColor = "rgba(0, 0, 0, 0.8)"
  size = "medium"
  color = "#fff"
  type="square-loader"
  [name]="'main'"
  [fullScreen] = "true">
  <p style="color: white" > Cargando... </p>
</ngx-spinner>
