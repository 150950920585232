import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CognitoService} from "../../../services/auth/cognito.service";
import {UtilsService} from "../../../services/utils.service";
import {SupplierModel} from "../../shared/models/supplier.model";
import {ClientModel} from "../../shared/models/client.model";
import {ClientDataService} from "../services/client-data.service";
import {ManagerService} from "../../../services/shared/manager.service";

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate, CanActivateChild {
  constructor(
    private cognito: CognitoService,
    private _util: UtilsService,
    private _clientData: ClientDataService,
    private router: Router,
    private _manager: ManagerService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkClient();
      check? resolve(true): reject("Check false")
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkClient();
      check? resolve(true): reject("Check false")
    });  }

  async checkClient(){
    const accessToken = await this.cognito.getCredsUser('access')
    if(accessToken){
      this._util.toogleFullSpinner(true)
      return await this.cognito.signInApi()
        .then((data: any) => {
          console.log('client guard data: ', data)
          this._util.toogleFullSpinner(false)
          let client: ClientModel | null = data.user
          if(!['client', 'manager_client'].includes(data.group)){
            this.router.navigate(['/landing/client'])
            return false;
          }
          if (data.group === 'manager_client') {
            client = data.user.client
            this._clientData.setClient(client)
            this._manager.setManager(data.user, data.user.isManager)
            return true
          }
          this._clientData.setClient(client)
          this._manager.setManager(data.user.managers)
          return true
        })
        .catch(err =>{
          this._util.toogleFullSpinner(false)
          this.router.navigate(['/landing'])
          this.cognito.logOut()
          return false;
        })
    }
    this.router.navigate(['/landing'])
    return false;
  }

}
