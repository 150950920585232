import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CognitoService} from "../../../services/auth/cognito.service";
import {UtilsService} from "../../../services/utils.service";
import {SupplierModel} from "../../shared/models/supplier.model";

@Injectable({
  providedIn: 'root'
})
export class AdministratorGuard implements CanActivate, CanActivateChild {


  constructor(
    private cognito: CognitoService,
    private _util: UtilsService,
    private router: Router
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkAdmin();
      check? resolve(true): reject("Check false")
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject)=>{
      const check = await this.checkAdmin();
      check? resolve(true): reject("Check false")
    });  }

  async checkAdmin(){
    const accessToken = await this.cognito.getCredsUser('access')
    if(accessToken){
      this._util.toogleFullSpinner(true)

      return await this.cognito.signInApi()
        .then((data: any) => {
          this._util.toogleFullSpinner(false)
          if(!data || data.group !== 'administrator' ){
            this.router.navigate(['/landing'])
            return false;
          }
          return true
        })
        .catch(err =>{
          this._util.toogleFullSpinner(false)
          this.router.navigate(['/landing'])
          this.cognito.logOut()
          return false;
        })
    }
    await this.router.navigate(['/landing'])
    return false;
  }

}
