export const environment:any = {
  // apiURL: 'http://localhost:3000',
  publicMediaUri: 'https://konnectos-dev-public-media.s3.us-west-2.amazonaws.com',
  prod: false,
  apiURL: 'https://6toivvkcuh.execute-api.us-west-2.amazonaws.com',
  cognito:{
    userPoolId: 'us-west-2_doG7Kuife',
    userPoolWebClientId: '65f56l79unonuooc1ouft3ta1t'
  },
  cognitoGroups:{
    0: 'client',
    1: 'supplier'
  }
}
