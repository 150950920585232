import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {CognitoService} from "../auth/cognito.service";
import { BehaviorSubject } from 'rxjs';
import { ManagerModel } from '../../modules/shared/models/manager.model';
import { NotificationModel } from '../../modules/shared/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  url = `${environment.apiURL}/manager`
  manager: BehaviorSubject<ManagerModel | null> = new BehaviorSubject<ManagerModel | null>(null);
  notifications:BehaviorSubject<NotificationModel[] | null> = new BehaviorSubject<NotificationModel[] | null>(null)

  constructor(private http:HttpClient, private cognito:CognitoService) { }

  async verifyCode(email: string, code: string){ {
    const token = await this.cognito.getCredsUser('access');
    return this.http.post(
      `${this.url}/verify`,
        {email, code},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }
 }

 async createPassword(email: string, newPassword: string){
    const token = await this.cognito.getCredsUser('access');
    return this.http.put(
      `${this.url}/changePassword`,
      {email, newPassword},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getManager(){
    return this.manager.getValue()
  }
  
  setManager(manager: ManagerModel, isManager?: boolean){
    manager.isManager = isManager
    this.manager.next(manager)
    console.log('setManager mangaer service:', this.manager.getValue())
  }

  isManager(){
    return this.manager.getValue()?.isManager
  }

 }
