import { Injectable } from '@angular/core';
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {Amplify, Auth} from "aws-amplify";
import {environment} from "../../../environments/environment";
import {AuthModel} from "../../modules/shared/models/AuthModel";
import {CookieService} from "ngx-cookie-service";
import {HttpClient} from "@angular/common/http";
import addMonths from 'date-fns/addMonths'
export const authErrors: any = {
  'An account with the given email already exists.': `model.auth.errors.email`,
  "Value at 'password' failed to satisfy constraint":`model.auth.errors.password`,
}
@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;
  private signUpTempCred?: any;

  constructor(private cookiesService: CookieService, private _http: HttpClient) {
    Amplify.configure({
      Auth: environment.cognito,
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public verifyCognito(username: string, code: string){
    return this._http.post(`${environment.apiURL}/auth/verify-email?userName=${username}&confirmationCode=${code}`,{})
  }

  public signUp(user: AuthModel, group: string): Promise<any> {
    const attributes: any = {};
    attributes['custom:group'] =  group
    if(attributes['custom:group'] === 'supplier'){
      attributes['custom:company_name'] = user.companyName;
      attributes['custom:verified'] = "0";
    }else if(attributes['custom:group'] === 'client'){
      attributes['custom:initialName'] = user.initialName;
      attributes['custom:initialSurname'] = user.initialSurname;
      attributes['custom:verified'] = "1";
    }
    return Auth.signUp({
      username: user.email,
      password: user.password,
      attributes:attributes
    });
  }

  public signIn(user: AuthModel): Promise<any> {
    return Auth.signIn({
      username: user.email,
      password: user.password,
    });
  }
  
  recoverPassword(email:string, password:string, code:string){
    return Auth.forgotPasswordSubmit(email, code, password)
  }


  changePassword(password: string, newPassword: string){
    return Auth.currentAuthenticatedUser().then(user => {
      return Auth.changePassword(user, password, newPassword);
    });
  }

  private async refreshToken(creds: any[]){
    const url = `${environment.apiURL}/auth/refresh`;
    const newToken: any = await this._http.post(url,{refreshToken: creds[1]}).toPromise();
    if(creds[3]){
      this.setUser(creds[1], new Date().setUTCSeconds(newToken?.ExpiresIn), newToken?.AccessToken,false, new Date(creds[3]))
    }else{
      this.setUser(creds[1], new Date().setUTCSeconds(newToken?.ExpiresIn), newToken?.AccessToken,false)
    }
    return [newToken?.AccessToken, creds[1], new Date().setUTCSeconds(newToken?.ExpiresIn)]
  }

  logOut(){
    this.cookiesService.delete('konnectos', '/')
  }

  async signInApi(){
    const url = `${environment.apiURL}/auth/login`;
    const token = await this.getCredsUser('access');
    return await firstValueFrom(this._http.get(url,{
      headers:{
        authorization: `Bearer ${token}`
      }
    }))
  }

  setUser(refreshToken: string, exp: number, accessToken: string, remember?: boolean, date?: Date){
    if(remember){
      this.cookiesService.set('konnectos', [accessToken,refreshToken,exp, addMonths(new Date(), 1)].join('%%k%%'),addMonths(new Date(), 1),"/" )
    }else if(date){
      this.cookiesService.set('konnectos', [accessToken,refreshToken,exp, date].join('%%k%%'), date,"/" )
    }else{
      this.cookiesService.set('konnectos', [accessToken,refreshToken,exp].join('%%k%%'),0,"/")
    }
  }

  forgotPassword(email: string){
    return Auth.forgotPassword(email);
  }

  async getCredsUser(cred: 'access' | 'refresh'){
    try {
      let creds = this.cookiesService.get('konnectos').split('%%k%%');
      if(new Date(Number(creds[2])) < new Date()){
        creds = await this.refreshToken(creds)
      }
      if(cred === 'access'){
        return creds[0]
      }else{
        return creds.slice(1,3)
      }
    }catch (e) {
      console.log(e);
      return '';
    }
  }

  setTempCred(authDto: {password:string, email:string}){
    this.signUpTempCred = authDto;
  }

  getTempCred(){
    return this.signUpTempCred;
  }

  getTempCookieClient(){
    return this.cookiesService.get('tempKonnectosClient')
  }

  getTempCookieSupplier(){
    return this.cookiesService.get('tempKonnectos')
  }
}
